<template>
  <div class="topNav">
    <!-- 顶部导航栏 -->
    <div
      class="top-nav"
      :class="[
        {
          fixedbtn1: this.alllist.isMenu ? 'fixedbtn1' : '',
        },
        {
          fixedbtn: !this.fixed && !this.alllist.isMenu ? 'fixedbtn' : '',
        },
      ]"
      id="home"
      ref="topnav"
    >
      <div class="nav">
        <router-link to="/">
          <div class="nav-left">
            <img src="../assets/image/fqkj1.png" />
            <img
              src="../assets/image/fqkj2.png"
              v-show="!this.alllist.isMenu && !this.fixed"
            />
          </div>
        </router-link>
        <div class="nav-right">
          <div class="nav-ri-home">
            <span data-id="home" @click="goto">
              <router-link to="/" style="color:#46494c;">
                首页
              </router-link>
            </span>
          </div>
          <!-- <div class="nav-ri-home" style="position:relative;line-height:44px">
            <span :class="!this.activeTop ? 'active' : ''">番茄品牌</span>
            <img
              v-show="!this.fixed"
              class="navArrows"
              src="../assets/image/downicn.png"
            />
            <img class="navArrows" src="../assets/image/downicn2.png" />
            <div
              class="nav-level-second"
              style="background-color: #fff;border: 1px solid #eee;"
            >
              <div v-for="(v, i) in this.tablist" :key="i">
                <router-link :to="'/' + v.line">
                  <a href="#" class="second-item" style="color:#333">
                    {{ v.name }}
                  </a>
                </router-link>
              </div>
            </div>
          </div> -->
		  <div class="nav-ri-home">
		    <span data-id="cpty">
				<router-link to="/news">
					<a style="color:#333">
					  新闻动态
					</a>
				</router-link>
			</span>
		  </div>
		  
          <div class="nav-ri-home">
            <span data-id="cpty" @click="goto">产品体验</span>
          </div>
          <!-- <div class="nav-ri-home">
            <span data-id="hzhb" @click="goto">合作伙伴</span>
          </div> -->
          <!-- <div class="nav-ri-home" style="position:relative;line-height:44px"> -->
          <!-- <span :class="this.activeTop ? 'active' : ''">新闻动态</span> -->
          <!-- <img
              v-show="!this.fixed"
              class="navArrows"
              src="../assets/image/downicn.png"
            />
            <img class="navArrows" src="../assets/image/downicn2.png" />
            <div
              class="nav-level-second2"
              style="background-color: #fff;border: 1px solid #eee;"
            >
              <router-link to="/news">
                <a style="color:#333">
                  公司新闻
                </a>
              </router-link>
              <router-link to="/news">
                <a style="color:#333">
                  行业资讯
                </a>
              </router-link>
            </div> -->
          <!-- </div> -->
          <div class="nav-ri-home">
            <span data-id="lxwm" @click="goto">联系我们</span>
          </div>
        </div>
        <div
          class="nav-right-menu"
          v-show="!this.alllist.isMenu"
          :class="this.alllist.isMenu || !this.fixed ? 'menuactive' : ''"
          @click="togomenu"
          ref="menuText"
        >
          O
        </div>
        <div
          class="nav-right-menu"
          v-show="this.alllist.isMenu"
          :class="this.alllist.isMenu ? '' : 'menuactive'"
          @click="togomenu"
          ref="menuText"
        >
          X
        </div>
      </div>
    </div>

    <!-- 移动隐藏菜单star -->
    <div
      class="top-menu"
      :class="this.alllist.isMenu ? 'menu-show' : ''"
      ref="menu"
    >
      <div class="top-menu-nav">
        <div class="nav-ri-home" data-id="home" @click="gotob">
          <router-link to="/">
            首页
          </router-link>
        </div>
        <!-- <div class="mobileTab-list">
          <van-collapse v-model="activeNames">
            <van-collapse-item name="1">
              <template #title>
                <div class="mobile-item">
                  <div style="color:#ffd6d6">
                    番茄品牌
                  </div>
                </div>
              </template>
              <div class="tabList">
                <div class="item" v-for="(v, i) in this.tablist" :key="i">
                  <router-link :to="'/' + v.line">
                    <div class="item-text">
                      {{ v.name }}
                    </div>
                  </router-link>
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div> -->
		<div class="nav-ri-home" data-id="cpty">
			<router-link to="/news">
			  <div class="item-text" style="color: #FFFFFF;">
			    新闻动态
			  </div>
			</router-link>
		</div>
        <div class="nav-ri-home" data-id="cpty" @click="gotob">产品体验</div>
        <!-- <div class="nav-ri-home" data-id="hzhb" @click="gotob">合作伙伴</div> -->
        <div class="nav-ri-home" data-id="lxwm" @click="gotob">联系我们</div>
      </div>
    </div>
    <!-- 移动隐藏菜单end -->
  </div>
</template>

<script>
import "../assets/less/topNav.less";
import alllist from "../assets/js/index";
export default {
  data() {
    return {
      // *全部列表
      alllist,
      activeNames: [1, 2, 3],
      fixed: false,
      activeTop: false,
      tablist: [
        {
          id: 1,
          name: "金融",
          line: "financial",
        },
        {
          id: 2,
          name: "积分",
          line: "integral",
        },
        {
          id: 3,
          name: "营销",
          line: "marketing",
        },
        {
          id: 4,
          name: "福利",
          line: "welfare",
        },
      ],
    };
  },
  created() {
    if (this.$route.path == "/news" || this.$route.path == "/newsDetail") {
      this.activeTop = true;
    } else {
      this.activeTop = false;
    }
  },
  methods: {
	  tonews(){
		  this.$router.push({
		  	path:"/news"})
	  },
    //*跳转到对应位置
    goto(e) {
      let id = e.target.dataset.id;
      let scrheight = document.querySelector("#" + id);
      if (id == undefined || scrheight == null) {
        this.$router.push({
          name: "Home",
        });
      } else {
        window.scrollTo({
          top: scrheight.offsetTop,
          behavior: "smooth",
        });
      }
    },

    gotob(e) {
      this.goto(e);
      this.togomenu();
    },

    // *菜单切换
    togomenu() {
      if (this.alllist.isMenu) {
        this.alllist.isMenu = false;
      } else {
        this.alllist.isMenu = true;
      }
      console.log(this.alllist.isMenu);
    },

    // 手机端展开b
    mobileOpen(item) {
      if (item.mobileSmaller) {
        return;
      } else {
        item.mobileSmaller = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.router-link-active {
  > .second-item {
    color: #5ab5fc !important;
  }
}

.router-link-active {
  > .item-text {
    color: #ffd6d6 !important;
  }
}
</style>
