<template>
  <div class="newsDetail">
    <TopNav></TopNav>
    <!-- 背景开始 -->
    <div class="top-bg">
      <div class="top-bg-content">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>新闻动态</div>
            <div>
              用中标资讯、运营干货一键掌握
            </div>
          </div>
        </div>
      </div>
      <div class="top-bg-content-phone">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>小蚂蚁新闻动态</div>
            <div>
              新闻动态抢先看，从权益输出到品牌赋能
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 背景结束 -->

    <!-- 详情全部开始 -->
    <div class="pcAll">
      <div class="contentDetail">
        <p class="detail-nav">
          <a @click="goback">新闻动态></a>
          <span class="title">
            {{ article.title }}
          </span>
        </p>
        <p class="detail-title">
          {{ article.title }}
        </p>
        <p class="detail-time">
          <span>{{ article.created_at }}</span>

          <span style="margin-left: 20px;">浏览量：{{ article.look }}</span>
        </p>
        <i class="time-border"></i>
        <div class="detail-all" v-html="article.content">
          <!-- 		  {{article.content}} -->
          <!-- <p>
          移动互联网浪潮后，用户对传统营销方式已经产生“抗体”。因此，各大银行纷纷开启数字化转型，橙券为银行数字化运营提供了一种新思路：通过游戏化营销，激活沉睡用户，提升用户活跃度，加快银行数字化转型进程。
        </p>
        <p>
          <img src="../assets/image/bannarImg1.png" alt="" />
        </p>
        <p>
          <strong>·以游戏促进用户活跃</strong>
          <br />
          <strong>橙券探索游戏化营销新思路</strong>
        </p>
        <p>
          移动互联网浪潮后，用户对传统营销方式已经产生“抗体”。因此，各大银行纷纷开启数字化转型，橙券为银行数字化运营提供了一种新思路：通过游戏化营销，激活沉睡用户，提升用户活跃度，加快银行数字化转型进程。
        </p>
        <p>
          <img src="../assets/image/bannarImg1.png" alt="" />
        </p> -->
        </div>
        <!-- @click="Previous"  @click="Next"-->
        <!-- <div class="detail-page">
				
				<span class="left" >上一篇</span>
				<span class="right" >下一篇</span>
			</div> -->
      </div>
    </div>
    <!-- 详情全部结束 -->
    <!-- 详情全部开始 移动端 -->
    <div class="phoneAll">
      <div class="contentDetail">
        <p class="detail-nav">
          <a @click="goback">新闻动态></a>
          <span class="title">
            {{ article.title }}
          </span>
        </p>
        <p class="detail-title">
          {{ article.title }}
        </p>
        <p class="detail-time">
          <span>{{ article.created_at }}</span>
          <span style="margin-left: 20px;">浏览量：{{ article.look }}</span>
        </p>
        <i class="time-border"></i>
        <div class="detail-all" v-html="article.content"></div>
      </div>
    </div>
    <!-- 详情全部结束 -->

    <Bottom></Bottom>
  </div>
</template>

<script>
import "../assets/less/newsDetail.less";
import TopNav from "../components/topNav";
import Bottom from "../components/Bottom";
import axios from "axios";
export default {
  data() {
    return {
      id: "",
      article: {},
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.request({
        id: this.id,
      });
    }
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    Previous() {
      this.article = {};
      this.id = this.id - 1;
      this.request({
        id: this.id,
      });
    },
    //调用接口获取新闻列表
    request(requestData) {
      console.log("requestData", requestData);
      let that = this;
      axios({
        method: "GET",
        // https://antwechat.yqhinfo.com/article/news
        url: "https://antwechat.yqhinfo.com/article/article",
        params: requestData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        baseURL: "https://yqhinfo.com",
      }).then(
        function(res) {
          if (res.data.code == 0) {
            that.article = res.data.data;
          } else {
            console.log(res);
            that.$toast({
              message: res.data.message,
            });
          }
        },
        function(error) {
          console.log("error", error);
        }
      );
    },
  },
  components: {
    TopNav,
    Bottom,
  },
};
</script>

<style lang="less" scoped></style>
